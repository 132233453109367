import React, { useEffect } from "react"
import { navigate } from "gatsby"
import localForage from "localforage"

// Components
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Intro from "../components/Intro"
import ProgressTracker from "../components/ProgressTracker"
import ThemesForm from "../components/forms/ThemesForm"

interface IData {
  role?: string
  organization?: string
  experience?: "yes" | "no"
  theme?: string
  subjects?: string[]
}

const ThemesPage = () => {
  const redirectWhenNoData = async () => {
    const data = (await localForage.getItem("data")) as IData
    if (data === null || !data.role || !data.organization || !data.experience) {
      navigate("/details")
    }
  }

  useEffect(() => {
    redirectWhenNoData()
  }, [])

  return (
    <Layout>
      <SEO title="Pick a topic" />
      <ProgressTracker currentStep={2} />
      <Intro
        title="Pick a topic"
        text={[
          "From your perspective, which topic adds the most value to a design system? ",
        ]}
      />
      <ThemesForm />
    </Layout>
  )
}

export default ThemesPage
