import React, { SyntheticEvent, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import localForage from "localforage"

// Data
import { themeData } from "../../data/themeData"

// Styling
import mq from "../../styles/breakpoints"

// Components
import ThemeRadio from "./fields/ThemeRadio"
import FieldError from "./fields/FieldError"
import PageNavigation from "../PageNavigation"

const ThemesForm = () => {
  const [selectedTheme, setSelectedTheme] = useState(null)
  const initialValues = {
    theme: null,
  }

  const validationSchema = Yup.object().shape({
    theme: Yup.mixed().test(
      "selectTheme",
      "It is required to select a theme",
      value => Object.keys(themeData).includes(value)
    ),
  })

  const handleNextStep = async (values: any) => {
    const currentData = await localForage.getItem("data")
    const updatedData = { ...currentData, theme: values.theme }
    localForage.setItem("data", updatedData, () => {
      navigate(
        `/themes/${themeData[values.theme].name
          .toLowerCase()
          .replace(" ", "-")}`
      )
    })
  }

  const handleChange = value => {
    setSelectedTheme(value.theme)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => handleNextStep(values)}
      validate={value => handleChange(value)}
    >
      {({ errors, touched }) => (
        <StyledForm>
          <RadioContainer>
            {Object.keys(themeData).map((theme: any) => {
              const iconName = theme.split("-")[1]

              return (
                <StyledThemeRadio
                  isInactive={
                    (selectedTheme !== null && selectedTheme !== theme) ||
                    selectedTheme
                  }
                  key={theme}
                  themeId={theme}
                  description={themeData[theme].description}
                  color={themeData[theme].color}
                  icon={iconName}
                />
              )
            })}
          </RadioContainer>
          {errors.theme && touched.theme && (
            <StyledFieldError error={errors.theme} />
          )}
          <PageNavigation label="Continue" from="/details" />
        </StyledForm>
      )}
    </Formik>
  )
}

const StyledFieldError = styled(FieldError)`
  margin-bottom: 16px;
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

const RadioContainer = styled.div`
  margin-bottom: 16px;

  ${mq.from.breakpoint.S`
    margin-bottom: 30px;
  `};

  + ${StyledFieldError} {
    margin-top: -18px;

    ${mq.from.breakpoint.S`
      margin-top: -30px;
  `};
  }
`

const StyledThemeRadio = styled(ThemeRadio)`
  margin-bottom: 24px;
`

export default ThemesForm
