import React from "react"
import styled from "styled-components"
import { Field } from "formik"

// Data
import { themeData } from "../../../data/themeData"

// Styling
import textStyles from "../../../styles/textStyles"
import colors from "../../../styles/colors"
import mq from "../../../styles/breakpoints"

// Components
import Icon from "../../icons/Icon"

// Types
import { IIconType } from "../../icons/Icon"

interface IProps {
  themeId: string
  description: string
  color: string
  icon: string
  className?: string
  isInactive: boolean | null
}

const ThemeRadio = ({
  themeId,
  description,
  color,
  icon,
  className,
  isInactive,
}: IProps) => (
  <Container className={className} isIncative={isInactive === true}>
    <Field
      as={StyledField}
      id={themeId}
      name="theme"
      value={themeId}
      color={color}
    />
    <Label htmlFor={themeId}>
      <IconContainer>
        <Icon icon={icon as IIconType} width={64} height={64} />
      </IconContainer>
      <ContentWrapper>
        <Title>{themeData[themeId].name}</Title>
        <Description>{description}</Description>
      </ContentWrapper>
    </Label>
  </Container>
)

interface IContainer {
  isIncative: boolean
}

const Container = styled.div<IContainer>`
  position: relative;
  transition: opacity 0.6s ease-in-out;

  ${({ isIncative }) => isIncative === true && `opacity: 0.55`}
`

const IconContainer = styled.div`
  position: relative;
  z-index: 1;
`

interface ILabel {
  htmlFor: string
}

const Label = styled.label<ILabel>`
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 20px;
  position: relative;

  box-shadow: 0 2px 4px 0 rgba(169, 169, 169, 0.25);
  overflow: hidden;

  :after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background-color: ${colors.white};
    border: 4px solid ${colors.white};
    border-radius: 8px;
  }

  ${mq.from.breakpoint.S`
    padding: 28px;
  `};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  position: relative;
  z-index: 1;

  ${mq.from.breakpoint.S`
    margin-left: 32px;
  `};
`

const StyledField = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;

  :checked + ${Label} {
    :after {
      ${({ color }) => color && `border: 4px solid ${color}`};
    }
  }

  :focus + ${Label} {
    outline: 2px solid Highlight;
  }
`

const Title = styled.h2`
  ${textStyles.subtitleSmall};
  margin-bottom: 8px;
`

const Description = styled.p`
  color: ${colors.grey};
  margin-bottom: 0;
`

export default ThemeRadio
